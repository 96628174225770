<script setup lang="ts">
import { storeToRefs } from 'pinia'

import StandAloneBandSignupInit from '~/components/band/signup/init.vue'
import IndexMainTitle from '~/components/index/mainTitle.vue'
import IndexVideoPresentation from '~/components/index/videoPresentation.vue'
import { Base500White } from '~/components/typography'
import { BtnOrange } from '~/components/ui/Buttons'
import LogoIcon from '~/components/ui/LogoIcon/LogoIcon.vue'
import VText from '~/components/ui/VText.vue'

import { useGetMediaUrl } from '~/composables/useGetMediaUrl'

import { useMiscResizeStore } from '~/stores/miscResize'

import { trackEvent } from '~/helpers/LegacyTrackEvent'

import { Breakpoints } from '~/enums/breakpoints'

import type { UserKind } from '~/types/UserKind'

type Props = {
  cost?: string
  entityName: string
  picture: string
  referrerAgencyId?: number
  userKind: Exclude<UserKind, null>
}

const props = withDefaults(defineProps<Props>(), {
  cost: '2',
  referrerAgencyId: 0,
})

const route = useRoute()
const { getMediaUrl } = useGetMediaUrl()
const { t } = useI18n()
const { SCREEN_WIDTH } = storeToRefs(useMiscResizeStore())

const titleSize = computed(() =>
  SCREEN_WIDTH.value < Breakpoints.xs375
    ? '32'
    : SCREEN_WIDTH.value < Breakpoints.md
      ? '40'
      : '48',
)

const titleText = computed<string>(() =>
  props.userKind === 'influencer'
    ? t('shared.bandSignup.widgetTemplate.head.title.influencer', {
        entityName: props.entityName,
      })
    : t('shared.bandSignup.widgetTemplate.head.title.band', {
        entityName: props.entityName,
      }),
)

const subtitleText = computed<string>(() =>
  props.userKind === 'influencer'
    ? t('shared.bandSignup.widgetTemplate.head.subtitle.influencer')
    : t('shared.bandSignup.widgetTemplate.head.subtitle.band'),
)

function handleKnowMoreClick(): void {
  trackEvent(
    {
      category: 'Referral Signup',
      action: 'Click know more',
    },
    route,
  )
}
</script>

<template>
  <div>
    <div class="headWrapper">
      <div class="mainParentContainer tw-relative">
        <LogoIcon class="floatingLogo" />
        <div class="tw-grid tw-grid-cols-1 tw-gap-2">
          <img
            v-if="userKind === 'influencer'"
            class="picture"
            :src="picture"
            alt=""
          />
          <div v-else class="picture emoji">
            <img :src="getMediaUrl('cringe.svg')" />
          </div>
          <VText
            :cfg="`sans/${titleSize}/bold`"
            class="tw-text-center"
            color="white"
          >
            {{ titleText }}
          </VText>
          <Base500White class="tw-text-center">{{ subtitleText }}</Base500White>
        </div>
      </div>
    </div>
    <div id="signupScrollAnchor" class="contentWrapper">
      <div class="signupWrapper tw-mb-6 sm:tw-mb-12 md:tw-mb-20">
        <StandAloneBandSignupInit
          no-float
          :influencer-entity="
            userKind === 'influencer' ? entityName : undefined
          "
          :agency-entity="userKind === 'band' ? entityName : undefined"
          :referrer-agency-id="referrerAgencyId"
          is-stand-alone
        />
      </div>
      <IndexMainTitle class="titleBottomSpacing marginAdjust">
        <i18n-t keypath="index.title.howItWorks">
          <template #lineBreak>
            <br class="600:tw-hidden" />
          </template>
        </i18n-t>
      </IndexMainTitle>
      <div
        class="videoPresentationContainer offsettedBackground tw-pb-6 md:tw-pb-10"
      >
        <IndexVideoPresentation />
      </div>
      <a
        :href="$t('shared.bandSignup.widgetTemplate.link')"
        target="_blank"
        class="tw-flex tw-items-center tw-justify-center tw-bg-white tw-py-6 md:tw-py-10"
      >
        <BtnOrange @click="handleKnowMoreClick">{{
          $t('shared.bandSignup.widgetTemplate.foot.btn')
        }}</BtnOrange>
      </a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.icon-logo,
.floatingLogo {
  @apply tw-absolute tw-text-white;

  --tw-text-opacity: 0.1;
  --local-grid-offset: var(--grid-2);
  left: calc(var(--local-grid-offset) - var(--grid-gap));
  top: 24px;
  font-size: 400px;

  @screen md {
    --local-grid-offset: var(--grid-4);
    font-size: 480px;
  }

  @screen lg {
    --local-grid-offset: var(--grid-8);
  }
}

.offsettedBackground {
  @apply tw-relative tw-w-full;

  &::before {
    @apply tw-bg-orange-500 tw-bg-opacity-10;

    --offset: 124px;
    content: '';
    position: absolute;
    top: var(--offset);
    left: 0px;
    z-index: -1;
    width: 100%;
    height: calc(100% - var(--offset));

    @screen lg {
      --offset: 64px;
    }
  }

  &.videoPresentationContainer::before {
    --offset: 336px;

    @screen 375 {
      --offset: 353px;
    }
    @screen 600 {
      --offset: 328px;
    }
    @screen md {
      --offset: 404px;
    }
    @screen lg {
      --offset: 61px;
    }
  }
}

.headWrapper {
  @apply tw-relative tw-w-full tw-overflow-hidden tw-bg-orange-500 tw-p-6;

  @screen sm {
    @apply tw-pt-10;

    padding-bottom: 104px;
  }

  @screen md {
    @apply tw-pt-12;

    padding-bottom: 128px;
  }
}

.titleBottomSpacing {
  @apply tw-mb-6;

  @screen md {
    @apply tw-mb-10;
  }
}
.contentWrapper {
  --offset: 0px;

  @screen sm {
    --offset: -64px;
  }

  @screen md {
    --offset: -80px;
  }

  .marginAdjust {
    margin-top: var(--offset);
  }

  .signupWrapper {
    @apply tw-bg-gray-100;
    transform: translateY(var(--offset));

    @screen sm {
      @apply tw-mx-auto tw-bg-white tw-shadow;

      width: var(--page-width);
    }

    @screen md {
      width: var(--grid-6);
    }

    @screen lg {
      width: var(--grid-6);
    }
  }
}

.picture {
  @apply tw-mx-auto tw-h-10 tw-w-10 tw-rounded-full tw-object-cover tw-object-center tw-shadow-round;

  @screen 375 {
    @apply tw-h-14 tw-w-14;
  }

  @screen md {
    @apply tw-h-20 tw-w-20;
  }

  &.emoji {
    @apply tw-flex tw-items-center tw-justify-center tw-bg-white tw-shadow-round;

    > img {
      @apply tw-h-12 tw-w-12;
    }
  }
}
</style>
