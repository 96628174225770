import { get } from 'http'
import { acceptHMRUpdate, defineStore } from 'pinia'

import { provideGetInfluencerInteractions } from '~/api-core/Curators/GetCurator'

import type { StoreInfluencerInteraction } from '~/types/influencerInteraction'

const state = () => ({
  list: [] as StoreInfluencerInteraction[],
})

export type InfluencersInteractionsState = ReturnType<typeof state>

export const useInfluencersInteractionsStore = defineStore(
  'influencersInteractions',
  () => {
    const { coreFetch } = useProvideCoreFetch()

    const list = ref<StoreInfluencerInteraction[]>([])

    function SET_LIST(newList: StoreInfluencerInteraction[]) {
      list.value = newList
    }

    async function FETCH() {
      if (list.value.length) return list.value

      const getInfluencerInteractions =
        provideGetInfluencerInteractions(coreFetch)
      const results = await getInfluencerInteractions()

      SET_LIST(results)
      return results
    }

    const GET_INTERACTION_FROM_INFLUENCER_ID = computed(() => {
      return function (
        influencerId: number,
      ): StoreInfluencerInteraction | undefined {
        return list.value.find(
          (influencerInteraction) =>
            influencerInteraction.influencer_id === influencerId,
        )
      }
    })

    return {
      // state
      list,

      // getters
      GET_INTERACTION_FROM_INFLUENCER_ID,

      // actions
      SET_LIST,
      FETCH,
    }
  },
)

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useInfluencersInteractionsStore, import.meta.hot),
  )
}
