import { storeToRefs } from 'pinia'

import { useDraftStore } from '~/stores/draft'
import { useMiscResizeStore } from '~/stores/miscResize'
import { useTagStore } from '~/stores/tag'
import { useUserAgencyStore } from '~/stores/userAgency'
import { useUserBandSetStore } from '~/stores/userBandSet'

import { stringIsGrooverBucketPrefix } from '~/helpers/favorites/stringIsGrooverBucketPrefix'

import type Tag from '~/entities/tag'
import type TagType from '~/entities/tagType'
import type {
  GrooverBucketName,
  ListNamePrefixes,
} from '~/helpers/favorites/grooverBucket'
import type GrooverBucket from '~/helpers/favorites/grooverBucket'
import type Bucket from '~/types/bucket'
import type {
  AvailableBucketInputs,
  BucketClassicInput,
  BucketRecommendedInput,
} from '~/types/bucketNavigatorEmits'

export function useBucketSelection() {
  const { $pinia } = useNuxtApp()
  const { t, locale } = useI18n()
  const localePath = useLocalePath()
  const route = useRoute()

  const { SCREEN_WIDTH } = storeToRefs(useMiscResizeStore($pinia))
  const { id: DRAFT_ID } = storeToRefs(useDraftStore($pinia))
  const { GET_BAND_FROM_ID } = useUserBandSetStore($pinia)
  const { kind: AGENCY_KIND } = storeToRefs(useUserAgencyStore($pinia))
  const { GET_TAGS_FROM_IDS } = useTagStore($pinia)

  const { GET_TAG_FROM_ID, GET_TYPE_FROM_TAG_ID } = useTagStore($pinia)

  // FIXME do better
  // ...mapStores(useUserBandSetStore),

  const grooverMadePrefix = computed<string>(() =>
    AGENCY_KIND.value === 'Pr' ? 'Pr.' : '',
  )

  function emittedBucketIsRecommended(
    bucket: AvailableBucketInputs,
  ): bucket is BucketRecommendedInput {
    return bucket.bucketType === 'recommended'
  }

  function emittedBucketIsClassic(
    bucket: AvailableBucketInputs,
  ): bucket is BucketClassicInput {
    return bucket.bucketType === 'classic'
  }

  function bucketIsClassic(bucket: Bucket | GrooverBucket): bucket is Bucket {
    return Object.keys(bucket).includes('id')
  }

  function ssrGenerateGrooverBucketListNameFromDashedUrlParam(
    urlParam: string,
  ): GrooverBucketName {
    const prefixes = ssrExtractPrefixesFromDashedUrlParam(urlParam)
    const tags: Tag['id'][] = GET_TAGS_FROM_IDS(
      urlParam
        .split('_')
        .map((e) => Number(e))
        .filter((e) => !isNaN(e)),
    ).map((e) => e.id)

    return {
      prefixes: prefixes.length ? prefixes : null,
      tag_ids: tags.length ? tags : null,
    }
  }

  function ssrExtractPrefixesFromDashedUrlParam(
    urlParam: string,
  ): ListNamePrefixes[] {
    const out = [] as ListNamePrefixes[]

    for (const value of urlParam.split('_')) {
      if (!stringIsGrooverBucketPrefix(value)) return out
      else out.push(value)
    }

    return out
  }

  function bucketIsRecommended(
    bucket: Bucket | GrooverBucket,
  ): bucket is GrooverBucket {
    return Object.keys(bucket).includes('list_name')
  }

  function createPrefixArrayForName(
    bucket: Pick<GrooverBucket, 'list_name'>,
    { translate }: { translate: boolean },
  ) {
    let out: string[] = []

    if (bucket.list_name.prefixes !== null) {
      out = bucket.list_name.prefixes.map((key) => {
        if (translate) return t(`grooverBuckets.prefixes.${key}`)
        else return key
      })
    }

    return out
  }

  function createTagArrayForName(
    bucket: Pick<GrooverBucket, 'list_name'>,
    { translate }: { translate: boolean },
  ): string[] {
    return [
      ...(bucket.list_name.tag_ids
        ? bucket.list_name.tag_ids.reduce<string[]>((accumulator, tagId) => {
            const tagType: TagType | undefined = GET_TYPE_FROM_TAG_ID(tagId)
            const tag: Tag | undefined = GET_TAG_FROM_ID(tagId)

            if (tag && tagType) {
              accumulator.push(
                translate
                  ? t(`tags.${tagType.name}.${tag.name}`)
                  : tag.id.toString(),
              )
            }
            return accumulator
          }, [])
        : []),
    ]
  }

  function createNameArrayFromGrooverBucket(
    bucket: Pick<GrooverBucket, 'list_name'>,
    { translate }: { translate: boolean } = {
      translate: true,
    },
  ): string[] {
    return [
      ...createPrefixArrayForName(bucket, { translate }),
      ...createTagArrayForName(bucket, { translate }),
    ]
  }

  function createDashedNameFromGrooverBucket(
    bucket: Pick<GrooverBucket, 'list_name'>,
  ): string {
    let name = createNameArrayFromGrooverBucket(bucket, {
      translate: false,
    }).join('_')

    // In case the list_name only has one tag
    // to prevent confusion with calssic list when deserializing
    if (!name.includes('_')) name += '_'

    return name
  }

  function createReadableNameFromGrooverBucket(bucket: GrooverBucket): string {
    const config = { translate: false }
    const prefixes = createPrefixArrayForName(
      bucket,
      config,
    ) as ListNamePrefixes[]
    const tagIds = createTagArrayForName(bucket, config).map(Number)
    const prefixTranslateConfig = prefixes.reduce(
      (accumulator, e) => {
        accumulator[e] = t(`grooverBuckets.prefixes.${e}`)
        return accumulator
      },
      {} as Record<ListNamePrefixes, string>,
    )
    const tagTranslateConfig = tagIds.reduce(
      (accumulator, tagId) => {
        const tagType = GET_TYPE_FROM_TAG_ID(tagId)
        const tag = GET_TAG_FROM_ID(tagId)

        if (tag && tagType)
          accumulator[tagType.name] = t(`tags.${tagType.name}.${tag.name}`, 2)

        return accumulator
      },
      {} as Record<TagType['name'], string>,
    )

    return [
      t('grooverBuckets.prefixString', prefixTranslateConfig),
      prefixes.length && tagIds.length && locale.value === 'en' ? 'in' : '',
      t('grooverBuckets.tagString', tagTranslateConfig),
    ]
      .join(' ')
      .replace(/ +/g, ' ')
  }

  function isMadeByGroover(
    bucket: Bucket | GrooverBucket,
  ): bucket is GrooverBucket {
    return (bucket as Bucket).is_groover_made || bucketIsRecommended(bucket)
  }

  function generateAnyBucketName(bucket: Bucket | GrooverBucket): string {
    return bucketIsClassic(bucket)
      ? getClassicBucketName(bucket)
      : createReadableNameFromGrooverBucket(bucket)
  }

  function generateAnyBucketLink(bucket: Bucket | GrooverBucket): string {
    const inspectedBucketId = bucketIsClassic(bucket)
      ? bucket.id.toString()
      : createDashedNameFromGrooverBucket(bucket)
    const location = {
      path: '',
      query: {} as Record<string, string>,
    }

    if (SCREEN_WIDTH.value >= 1024) {
      location.path = '/agency/favorites/'
      location.query.inspectedBucketId = inspectedBucketId
    } else {
      location.path = `/agency/favorites/${inspectedBucketId}`
    }

    if (route.path.includes('/draft/')) {
      location.path = `/draft/${DRAFT_ID.value}/influencers/lists`
      location.query.inspectedBucketId = inspectedBucketId
    }
    return localePath(location)
  }

  function getClassicBucketName(bucket: Bucket) {
    if (bucket.is_groover_made) {
      if (!bucket.band) return ''

      const band = GET_BAND_FROM_ID(bucket.band)
      if (!band) return ''

      return t(
        `agency.favorites.list.${grooverMadePrefix.value}${bucket.name}`,
        {
          bandName: band.name,
        },
      )
    } else if (bucket.is_default_list) {
      return t(`agency.favorites.list.default_favorite`)
    } else {
      return bucket.name
    }
  }

  function grooverBucketEventData(bucket: GrooverBucket) {
    return {
      listName: bucket.list_name,
      serializedListName: createDashedNameFromGrooverBucket(bucket),
      bucketRank: bucket.rank,
    } as const
  }

  return {
    ssrGenerateGrooverBucketListNameFromDashedUrlParam,
    createDashedNameFromGrooverBucket,
    getClassicBucketName,
    grooverBucketEventData,
    generateAnyBucketLink,
    isMadeByGroover,
    generateAnyBucketName,
    emittedBucketIsRecommended,
    emittedBucketIsClassic,
    bucketIsRecommended,
    bucketIsClassic,
  }
}
